<script lang="ts">
	import Link from '../ui/Link.svelte';
	import Card from './Card.svelte';
	import Text from '$lib/components/input/text.svelte';
	import LoginButton from './LoginButton.svelte';
	import { apiFetch, type apiResponse } from '$lib/utils/fetch';
	import { closeModal } from 'svelte-modals';

	let errorMessage: string | null;
	let email: string;
	let passwordless_state: 'default' | 'loading' | 'success' | 'error' = 'default';

	async function handleSendPasswordReset() {
		passwordless_state = 'loading';
		errorMessage = null;

		apiFetch<apiResponse>('/auth/password/reset/send', {
			method: 'POST',
			body: { email }
		})
			.then((res) => {
				if (res.status === '200 OK') {
					passwordless_state = 'success';
					errorMessage =
						'Ifall e-postadressen är registrerad kommer ett mail skickas till den med en länk för att byta lösenord.';
					setTimeout(() => {
						closeModal();
					}, 10000);
				} else {
					console.log('Here');
					passwordless_state = 'error';
					errorMessage = 'Något gick fel. Försök igen.';
				}
			})
			.catch((err) => {
				if (err.status === 429) {
					passwordless_state = 'error';
					errorMessage = 'Du har skickat för många förfrågningar. Försök igen senare.';
					setTimeout(() => {
						passwordless_state = 'default';
						errorMessage = null;
					}, 5000);
				} else {
					passwordless_state = 'error';
					errorMessage = 'Något gick fel. Försök igen.';
				}
			});
	}
</script>

<Card>
	<div>
		<h1 class="text-3xl font-semibold text-left text-white">Byt lösenord</h1>
		<h4 class="text-left text-zinc-300">Återställ ditt lösenord med hjälp av din e-post.</h4>
		<span class="flex items-center py-1 space-x-2">
			<h4 class="text-sm xl:text-base text-zinc-400">Funkar det inte?</h4>
			<Link href={'https://discord.playpark.se'}>Kontakta oss</Link>
		</span>
	</div>

	<div class="flex flex-col items-center justify-center space-y-5">
		{#if errorMessage}
			<p
				class:error={passwordless_state === 'error'}
				class:success={passwordless_state === 'success'}
				class="flex justify-center w-full px-4 py-2 text-center transition-all duration-150 border rounded text-zinc-100"
			>
				{errorMessage}
			</p>
		{/if}
		<form class="flex flex-col w-full" on:submit|preventDefault={handleSendPasswordReset}>
			<div class="space-y-3">
				<Text label="E-post" label_for="email" bind:value={email} required={true} type="email" />
			</div>
			<div class="mt-6 space-y-2">
				<LoginButton state={passwordless_state} type="submit">
					<div class="relative flex items-center justify-center w-full px-4">Skicka mail</div>
				</LoginButton>
			</div>
		</form>
	</div></Card
>

<style>
	.success {
		border-color: rgb(34 197 94);
		background-color: rgb(34 197 94 / 0.3);
	}

	.error {
		border-color: rgb(197 34 34);
		background-color: rgb(197 34 34 / 0.3);
	}
</style>
